var debounce = require("lodash.debounce");
var desktopActive = false;
var rotator = setInterval(scroll, 5500);
var numRotators = document.querySelectorAll(".hero--section").length;

$(window).resize(function() {
  adjustPosterSize();

  if (desktopWindowBool()) {
    if (!desktopActive) {
      initScroll();
      desktopActive = true;
    }
  } else {
    desktopActive = false;
  }
});
$(window).ready(function() {
  if (desktopWindowBool()) {
    desktopActive = true;
    initScroll();
  }
  adjustPosterSize();
});

function desktopWindowBool() {
  if (window.innerWidth > 1200) {
    setDesktopSettings();
    return true;
  } else {
    setMobileSettings();
    return false;
  }
}

function adjustPosterSize() {
  var width = window.innerWidth;
  var height = window.innerHeight;

  var circles = $(".image circle");
  var images = $(".image image");

  var circleR = height * 0.9;
  var circleX = width * 0.9;
  var circleY = circleR;
  var imageW = "130%";
  var imageH = "130%";
  var imageX = width * 0.2;
  var imageY = "-15%";

  if (width <= 400) {
    imageX = "-100px";
    imageH = "170%";
    imageW = "170%";
    imageY = "-60%";
    circleR = 750;
    circleX = 730;
    circleY = 420;
  } else if (width <= 700) {
    imageX = "-100px";
    imageH = "140%";
    imageW = "140%";
    imageY = "-46%";
    circleR = 750;
    circleX = 730;
    circleY = 420;
  } else if (width <= 1200) {
    imageX = "-100px";
    imageH = "155%";
    imageY = "-40%";
    circleR = 750;
    circleX = 730;
    circleY = 420;
  } else if (width <= 1700) {
    circleX = width * 1.1;
  }

  if (height * 0.9 < 800 && width > 1200) {
    circleR = 800;
    circleX = width;
  }

  circles.attr("cx", circleX);
  circles.attr("cy", circleY);
  circles.attr("r", circleR);
  images.attr("x", imageX);
  images.attr("y", imageY);
  images.attr("width", imageW);
  images.attr("height", imageH);
}

function scroll() {
  var oldDataType = $(".hero--section.active").data("home-type");
  var newDataType;
  var maxDataType = "Home Section " + numRotators;

  if (oldDataType == maxDataType) {
    // switching from bottom section to top section on a downward scroll
    newDataType = "Home Section 1";
  } else {
    // switching from a section to homepage's next section
    newDataType = $(".hero--section[data-home-type='" + oldDataType + "']")
      .next()
      .data("home-type");
  }

  updateSwitcher(newDataType);
  toggleSection(newDataType, oldDataType);
  toggleBackground(newDataType, oldDataType);
}

function initScroll() {
  updateSwitcher("Home Section 1");
  $(".hero--section[data-home-type='Home Section 1'] .hero-text").addClass(
    "show-text"
  );
  $(".hero--section[data-home-type='Home Section 1']").addClass("top");
  $(".hero--section[data-home-type='Home Section 1']").addClass("active");
  $(".section-background[data-home-type='Home Section 1']").addClass("top");
  $(".section-background[data-home-type='Home Section 1']").addClass("active");
}

function toggleBackground(newDataType, oldDataType) {
  clearBackgrounds();
  $(".section-background[data-home-type='" + newDataType + "']").addClass(
    "active"
  );

  // set animations
  $(".section-background.active").addClass("scroll-down");
  $(".section-background.active").addClass("top");
  $(".section-background[data-home-type='" + oldDataType + "']").addClass(
    "bottom"
  );
}
function toggleSection(newDataType, oldDataType) {
  clearSections();
  $(".hero--section[data-home-type='" + newDataType + "']").addClass("active");
  $(".hero--section[data-home-type='" + oldDataType + "']").addClass(
    "inactive"
  );

  // set animations
  $(".hero--section.active").addClass("scroll-in");
  $(".hero--section.active").addClass("top");
  $(".hero--section[data-home-type='" + oldDataType + "']").addClass("bottom");
}

// switches
$(".switcher").on("click", function() {
  var newDataType = $(this).data("home-type");
  var currentDataType = $(".switcher.active").data("home-type");

  if (newDataType != currentDataType) {
    updateSwitcher(newDataType);
    toggleSection(newDataType, currentDataType);
    toggleBackground(newDataType, currentDataType);
    clearInterval(rotator);
  }
});

function updateSwitcher(dataType) {
  $(".switcher").removeClass("active");
  $(".switcher[data-home-type='" + dataType + "']").addClass("active");
}

// assist functions
function setMobileSettings() {
  $(".section-switcher").addClass("hidden");
  $(".section-background").addClass("hidden");
  $(".site-footer").removeClass("hidden");
  clearBackgrounds();
  clearSections();
  $(".hero--section").addClass("mobile");
  $(".hero--section").removeClass("desktop");
  $(".switcher").removeClass("active");
}
function setDesktopSettings() {
  $(".section-switcher").removeClass("hidden");
  $(".section-background").removeClass("hidden");
  $(".site-footer").addClass("hidden");
  $(".hero--section").removeClass("mobile");
  $(".hero--section").addClass("desktop");
}
function clearBackgrounds() {
  $(".section-background").removeClass("active");
  $(".section-background").removeClass("top");
  $(".section-background").removeClass("bottom");
  $(".section-background").removeClass("scroll-up");
  $(".section-background").removeClass("scroll-down");
}
function clearSections() {
  $(".hero--section[data-home-type='Home Section 1'] .hero-text").removeClass(
    "show-text"
  );
  $(".hero--section").removeClass("inactive");
  $(".hero--section").removeClass("active");
  $(".hero--section").removeClass("top");
  $(".hero--section").removeClass("bottom");
  $(".hero--section").removeClass("scroll-in");
  $(".hero--section").removeClass("scroll-out");
}
